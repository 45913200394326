import { useCallback, useState } from "react"
import { useSettings, useSettingsDispatch } from "../../contexts/SettingsContext"
import BranchActionLine from "./BranchActionLine"

/**
 * Component to add or remove configured district branch actions.
 * Relies on District Settings and dispatch.
 * @returns {React.JSX.Element}
 */
export default function BranchActions() {
  const [ value, setValue ] = useState("")
  const { groupSettings } = useSettings()
  const { branchActions } = groupSettings
  const dispatch = useSettingsDispatch()
  
  // Update field state
  const onChange = useCallback(event => {
    setValue(event.target.value)
  }, [])

  // Add entry to state
  const onClick = useCallback(() => {
    dispatch({
      type: "ADD_BRANCH_ACTION",
      payload: value
    })
    setValue("")
  }, [dispatch, value])

  return (
    <div id="branchActions">
      <h2>Branch Actions</h2>
      <div>
        <div>
          <label>Add New Action<input onChange={onChange} value={value} type="text"></input></label>
          <button onClick={onClick}>Add</button>
        </div>
        <div id="branchActionLineContainer">
          {
            branchActions.map(branchAction => <BranchActionLine key={branchAction} branchAction={branchAction} />)
          }
        </div>
      </div>
    </div>
  )
}